import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { nanoid } from "nanoid";
import { authContext } from "../contexts";
import { IRootReducerState } from "../redux/IRootReducer";
import eventBus from "../utils/event";
import { LOG_OUT, INITIALIZE } from "../redux/auth/auth.action";
import useAuth from "../hooks/useAuth";

const redirectUrl = "https://store.gogeeper.com/dashboard/get-started";
export const authBaseUrl = "https://auth.gogeeper.com/oauth/authorize";
//  export const authBaseUrl = 'http://localhost:3000/oauth/authorize';
const rolesToRedirect = ["Farmer", "AgricExpert"];

interface AuthContext {
  children: React.ReactNode;
}

const AuthProvider = ({ children }: AuthContext) => {
  const dispatch = useDispatch();
  const newAuth = useAuth(); // Use the auth hook
  const { isAuthenticated, user } = useSelector(
    (state: IRootReducerState) => state.auth
  );

  const initiatorID = nanoid(35);
  const fromProduct = "Marketplace";

  const loginUrl = `${authBaseUrl}/login/fromUmlAssign/${fromProduct}/initiator_id/${initiatorID}?mainAppOrigin=${encodeURIComponent(
    window.location.origin
  )}`;
  const signupUrl = `${authBaseUrl}/onboarding/fromUmlAssign/${fromProduct}/initiator_id/${initiatorID}?mainAppOrigin=${encodeURIComponent(
    window.location.origin
  )}`;

  const openPopup = (
    url: string,
    eventName: string,
    title: string,
    width = 1000,
    height = 700
  ) => {
    const dualScreenLeft = window.screenLeft || window.screenX;
    const dualScreenTop = window.screenTop || window.screenY;

    const systemZoom = window.innerWidth / window.screen.availWidth;
    const left = (window.innerWidth - width) / 2 / systemZoom + dualScreenLeft;
    const top = (window.innerHeight - height) / 2 / systemZoom + dualScreenTop;

    const popup = window.open(
      url,
      title,
      `scrollbars=yes, width=${width}, height=${height}, top=${top}, left=${left}`
    );

    if (popup) {
      popup.focus();
      eventBus.emit(eventName, { popup });
    }
  };

  const triggerlogin = () =>
    openPopup(loginUrl, "loginAuthTriggerEvt", "gogeeper--login");
  const triggerSignUp = () =>
    openPopup(signupUrl, "signupAuthTriggerEvt", "gogeeper--signup");

  const triggerLogout = () => {
    newAuth?.setAuth({ accessToken: "" }); // Clear access token in the auth context
    dispatch(LOG_OUT()); // Reset Redux state
  };

  useEffect(() => {
    if (newAuth?.auth.accessToken && !isAuthenticated) {
      dispatch(INITIALIZE(newAuth?.auth.accessToken)); // Initialize authentication with token
    }
  }, [newAuth?.auth.accessToken, isAuthenticated, dispatch]);

  useEffect(() => {
    if (isAuthenticated) {
      if (rolesToRedirect.includes(user?.role)) {
        window.location.href = redirectUrl;
      } else {
        window.location.href = "https://marketplace.gogeeper.com/";
      }
    }
  }, [isAuthenticated, user]);

  return (
    <authContext.Provider
      value={{ triggerlogin, triggerSignUp, triggerLogout }}
    >
      {children}
    </authContext.Provider>
  );
};

export default AuthProvider;
