import { createSlice } from "@reduxjs/toolkit";
import { IAuth } from "./IAuth";

const initialState: IAuth = {
  loading: false,
  errMessage: "",
  error: false,
  isAuthenticated: false,
  user: null,
  isInitialized: false,
  // isVerified: false
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    // STARTS LOADING
    startLoading: (state) => {
      state.loading = true;
    },

    // HAS ERROR
    hasError: (state, action) => {
      state.loading = false;
      state.error = true;
      state.errMessage = action.payload;
    },

    // INITIALIZE
    initialize: (state, action) => {
      const { isAuthenticated, user } = action.payload;
      state.loading = false;
      state.isAuthenticated = isAuthenticated;
      state.isInitialized = true;
      state.user = user;
    },

    // LOGOUT
    logout: (state) => {
      state.loading = false;
      state.error = false;
      state.isAuthenticated = false;
      state.user = null;
    },
  },
});

const { actions: authActions, reducer: authReducer } = authSlice;
export { authActions, authReducer };
