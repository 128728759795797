import { createSlice } from "@reduxjs/toolkit";

const locationSlice = createSlice({
  name: "location",
  initialState: {
    city: "",
    lga: "",
    userId: "",
  },
  reducers: {
    updateCity: (state, action) => {
      state.city = action.payload;
    },
    updateLga: (state, action) => {
      state.lga = action.payload;
    },
    updateUserId: (state, action) => {
      state.userId = action.payload;
    },
  },
});

export const { updateCity, updateLga, updateUserId } = locationSlice.actions;
export const locationReducer = locationSlice.reducer;
