import { configureStore } from "@reduxjs/toolkit";
// import { createBrowserHistory } from 'history';
// import { persistStore, persistReducer } from 'redux-persist';
import { rootReducer } from "./rootReducer";

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
      thunk: {
        extraArgument: {
          api: {
            get: () => {
              return new Promise((resolve, reject) => {
                resolve(true);
              });
            },
          },
        },
      },
    }),
  devTools: true,
});
